/* POPPINS FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;900&display=swap");

/* IBM Plex Serif FONT */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #1a2537;
    --primary-rgb: 26 37 55;

    --secondary-color: #b0a180;
    --secondary-rgb: 176 161 128;

    --dark: #080919;
    --dark-rgb: 22 22 22;

    --light: #ffffff;
    --light-rgb: 255 255 255;
}

* {
    margin: 0;
    padding: 0;
    transition: all 0.1s ease-in-out;
    scroll-behavior: smooth;
}

html {
    overflow-x: hidden;
    min-height: 100vh;
    scroll-behavior: smooth;
}

#root {
    scroll-behavior: smooth;
    min-height: 100vh;
    position: relative;
}

body {
    font-family: "poppins", sans-serif;
    position: relative;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    box-sizing: border-box;
    scroll-behavior: smooth;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    background: var(--primary-color);
    background-image: linear-gradient(
            rgba(var(--primary-rgb) / 0.4),
            rgba(var(--primary-rgb) / 0.4)
        ),
        url("./assets/images/dark-blue-background.jpg");
    background-size: cover;
    background-attachment: fixed;
    color: #ffffff;
}

input,
select,
textarea {
    outline: 0;
}

input:focus,
select:focus,
textarea:focus {
    box-shadow: 0px 0px 2px 2px rgb(var(--primary-rgb), 0.4),
        0px 0px 2px 4px rgba(var(--primary-rgb), 0.3),
        0px 0px 2px 4px rgba(var(--primary-rgb), 0.2);
}

.breaker {
    word-wrap: break-word;
    word-break: break-all;
    hyphenate-limit-chars: 8 2;
    hyphens: auto;
    line-break: strict;
    overflow-wrap: break-word;
    white-space: break-spaces;
    hanging-punctuation: first;
    line-clamp: 3;
    text-overflow: ellipsis;
}

.scroll-hidden {
    scrollbar-width: none;
}

/* Hide vertical scrollbar */
.scroll-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide horizontal scrollbar */
.scroll-hidden::-webkit-scrollbar-horizontal {
    display: none;
}

.font-poppins {
    font-family: "poppins", sans-serif;
}

.font-header {
    font-family: "IBM Plex Serif", serif;
}

.wrapper {
    width: 98%;
    max-width: 1350px;
    margin: auto;
    height: 100%;
}

.shadow-text {
    text-shadow: 2px 2px 4px rgba(var(--dark-rgb), 5);
}

.text-stroke {
    -webkit-text-stroke: 1px var(--dark);
}

.user-select-primary::selection {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.user-select-secondary::selection {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

* {
    scrollbar-width: thin; /* Thin scrollbar */
}

*::-webkit-scrollbar {
    width: 9px;
    background-color: rgba(var(--light-rgb));
    height: 9px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(var(--secondary-rgb));
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--secondary-rgb) / 0.8);
}

/* ANIMATION KEYFRAMES */

@keyframes bounceLR {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(-10px);
    }
    60% {
        transform: translateX(10px);
    }
}

@keyframes moveLR {
    0% {
        left: 0%;
    }
    10% {
        left: 20%;
    }
    20% {
        left: 40%;
    }
    30% {
        left: 60%;
    }
    40% {
        left: 80%;
    }
    50% {
        left: 90%;
    }
    60% {
        left: 70%;
    }
    70% {
        left: 50%;
    }
    80% {
        left: 20%;
    }
    90% {
        left: 10%;
    }
    100% {
        left: 0%;
    }
}

.title-animated {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: var(--light);
    width: fit-content;
}

.title-animated::after {
    content: "";
    width: 100%;
    display: block;
    border: 3px solid var(--secondary-color);
}

.title-animated::before {
    content: "";
    width: 40px;
    display: block;
    border: 3px solid var(--primary-color);
    position: absolute;
    bottom: 0;
    transition: 2s ease;
    animation: moveLR 2.5s linear infinite;
    animation-delay: 1s;
}

.bounceLeftRight {
    animation: bounceLR 1s linear infinite;

    font-size: 3rem;
}
