.loading-component {
    position: fixed;
    z-index: 3;
    background: rgba(var(--primary-rgb) / 0.9);
    backdrop-filter: blur(4px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
