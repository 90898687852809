.rain {
    height: 100%;
    min-height: 100vh;
    background: url("../../assets/icons/rain.png");
    animation: AniRain 0.3s linear infinite;
    transition: all 1s ease;
    opacity: 0.1;
}

.rain::before {
    transition: all 1s ease;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    animation: light 3s linear infinite;
    opacity: 0.2;
    z-index: -10;
}

@keyframes AniRain {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 20% 100%;
    }
}

.rainBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hideRain {
    transition: all 1s ease;
    opacity: 0.01;
}
